<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle
      :title="
        myObj.status != 3
          ? $vuetify.lang.t('$vuetify.pickNew')
          : $vuetify.lang.t('$vuetify.returnRequest')
      "
    />

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="pa-0">
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.basicInfo") }}
              </span>
            </v-col>
          </v-row>

          <v-row v-if="myObj.status >= 1 && myObj.status != 3">
            <v-col class="py-0" cols="12">
              <v-stepper v-model="myObj.status" vertical class="mt-4">
                <v-stepper-step step="1" complete>
                  {{ $vuetify.lang.t("$vuetify.application") }}
                </v-stepper-step>

                <v-stepper-content step="1" class="pt-0">
                  <span class="pr-4">
                    {{ $vuetify.lang.t("$vuetify.applicant") }}
                  </span>

                  <span style="color: green">
                    {{ this.pickingInfoObj.userInfo_name }}<br /><br />
                  </span>

                  <span class="pr-4">
                    {{ $vuetify.lang.t("$vuetify.applicationTime") }}
                  </span>

                  <span style="color: green">
                    {{ this.pickingInfoObj.gen_time }}
                  </span>
                </v-stepper-content>

                <v-stepper-step step="2" :complete="myObj.status >= 2">
                  {{ $vuetify.lang.t("$vuetify.approve") }}
                </v-stepper-step>

                <v-stepper-content
                  step="2"
                  v-if="pickingInfoObj.approver != undefined"
                >
                  <span class="pr-4">
                    {{ $vuetify.lang.t("$vuetify.approver") }}
                  </span>

                  <span style="color: green">
                    {{ this.pickingInfoObj.approver }}<br /><br />
                  </span>

                  <span class="pr-4">
                    {{ $vuetify.lang.t("$vuetify.processingTime") }}
                  </span>

                  <span style="color: green">
                    {{ this.pickingInfoObj.approveDate }}
                  </span>
                </v-stepper-content>

                <v-stepper-step step="3" :complete="myObj.status == 3">
                  {{ $vuetify.lang.t("$vuetify.complete") }}
                </v-stepper-step>

                <v-stepper-content
                  step="3"
                  v-if="pickingInfoObj.cancel_user.name != undefined"
                >
                  <span class="pr-4">
                    {{ $vuetify.lang.t("$vuetify.determinedPerson") }}
                  </span>

                  <span style="color: green">
                    {{ this.pickingInfoObj.determinedPerson }}<br /><br />
                  </span>

                  <span class="pr-4">
                    {{ $vuetify.lang.t("$vuetify.determineTime") }}
                  </span>

                  <span style="color: green">
                    {{ this.pickingInfoObj.determineDate }}
                  </span>
                </v-stepper-content>
              </v-stepper>
            </v-col>
          </v-row>

          <v-row v-if="receiveStatus != 0">
            <v-col class="py-0" cols="12">
              <v-stepper v-model="receiveStatus" alt-labels>
                <v-stepper-header style="width: 750px" class="elevation-0">
                  <v-stepper-step complete step="1" class="pl-0">
                    {{ $vuetify.lang.t("$vuetify.applicationReturn") }}
                  </v-stepper-step>

                  <v-divider width="20"></v-divider>

                  <v-stepper-step
                    :complete="receiveStatus == -1"
                    step="2"
                    v-if="receiveStatus == -1"
                  >
                    {{ $vuetify.lang.t("$vuetify.abolish") }}
                  </v-stepper-step>

                  <v-stepper-step
                    :complete="receiveStatus >= 2"
                    step="2"
                    v-if="receiveStatus != -1"
                  >
                    {{ $vuetify.lang.t("$vuetify.approve") }}
                  </v-stepper-step>

                  <v-divider width="20"></v-divider>

                  <v-stepper-step :complete="receiveStatus >= 3" step="3">
                    {{ $vuetify.lang.t("$vuetify.offStocks") }}
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content :step="receiveStatus">
                    <v-card height="100px">
                      <v-row>
                        <v-col cols="4">
                          <span class="pr-4">
                            {{ $vuetify.lang.t("$vuetify.applicant") }}
                          </span>

                          <span style="color: green">
                            {{ this.receiveProxyData.user_info.name }}
                            <br /><br />
                          </span>

                          <span class="pr-4">
                            {{ $vuetify.lang.t("$vuetify.applicationTime") }}
                          </span>

                          <span style="color: green">
                            {{ this.receiveProxyData.gen_time }}
                          </span>
                        </v-col>

                        <v-col cols="4" v-if="receiveStatus != -1">
                          <span class="pr-4">
                            {{ $vuetify.lang.t("$vuetify.approver") }}
                          </span>
                          <span style="color: green">
                            {{ this.receiveProxyData.check_user.name }}
                            <br /><br />
                          </span>

                          <span class="pr-4">
                            {{ $vuetify.lang.t("$vuetify.processingTime") }}
                          </span>

                          <span style="color: green">
                            {{ this.receiveProxyData.check_user.opt_time }}
                          </span>
                        </v-col>

                        <v-col cols="4" v-if="receiveStatus == -1">
                          <span class="pr-4">
                            {{ $vuetify.lang.t("$vuetify.canceler") }}
                          </span>

                          <span style="color: green">
                            {{ this.receiveProxyData.cancel_user.name }}
                            <br /><br />
                          </span>

                          <span class="pr-4">
                            {{ $vuetify.lang.t("$vuetify.cancellationTime") }}
                          </span>

                          <span style="color: green">
                            {{ this.receiveProxyData.cancel_user.opt_time }}
                          </span>
                        </v-col>

                        <v-col cols="4">
                          <span class="pr-4">
                            {{ $vuetify.lang.t("$vuetify.approver") }}
                          </span>

                          <span style="color: green">
                            {{ this.receiveProxyData.putaway_user.name }}
                            <br /><br />
                          </span>

                          <span class="pr-4">
                            {{ $vuetify.lang.t("$vuetify.processingTime") }}
                          </span>

                          <span style="color: green">
                            {{ this.receiveProxyData.putaway_user.opt_time }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pb-0" cols="12" v-if="myObj.status != 3">
              <v-select
                :items="types"
                v-model="myObj.type"
                :label="$vuetify.lang.t('$vuetify.type')"
                :disabled="auditAction == true || myObj.status == 2"
              />
            </v-col>

            <v-col class="py-0" cols="12">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.remarks')"
                v-model="myObj.note"
                outlined
                class="pt-5"
                v-if="myObj.status != 3"
                :disabled="auditAction == true || myObj.status == 2"
              />

              <v-textarea
                :label="$vuetify.lang.t('$vuetify.remarks')"
                v-model="receiveForm.note"
                outlined
                class="pt-5"
                v-if="myObj.status == 3"
                :disabled="myObj.returnID != undefined"
              />
            </v-col>

            <!-- File Upload -->
            <v-col class="py-0" cols="12">
              <v-file-input
                v-model="files"
                class="pa-0 ma-0 mt-0"
                :label="$vuetify.lang.t('$vuetify.annex')"
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                accept="image/jpeg, image/png, application/pdf"
                :disabled="
                  editAction == false &&
                  addAction == false &&
                  myObj.returnStatus != undefined &&
                  myObj.returnStatus != '' &&
                  myObj.returnStatus != 1
                "
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>

            <!-- Order List Files -->
            <v-col cols="12" class="pt-0" v-if="myObj.attach.length > 0">
              <span class="display-1 font-weight-bold">
                List of Documents
              </span>

              <v-sheet
                elevation="2"
                class="py-2"
                :height="myObj.attach.length < 1 ? '200px' : ''"
              >
                <span
                  class="d-flex align-center justify-center"
                  v-for="(item, key) in myObj.attach"
                  :key="key"
                >
                  <v-list-item
                    class="tile ml-4 mr-2 my-2"
                    :href="dataUrl + item.md5"
                    target="_blank"
                  >
                    {{ item.filename }}
                  </v-list-item>

                  <v-btn
                    color="red"
                    class="ml-2 mr-4"
                    @click="removeAttach(item)"
                    v-if="addAction || editAction"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </span>
              </v-sheet>
            </v-col>

            <!-- Sale Return List Files -->
            <v-col cols="12" class="pt-0" v-if="receiveFileList.length > 0">
              <span class="display-1 font-weight-bold">
                List of Documents
              </span>

              <v-sheet
                elevation="2"
                class="py-2"
                :height="receiveFileList.length < 1 ? '200px' : ''"
              >
                <span
                  class="d-flex align-center justify-center"
                  v-for="(item, key) in receiveFileList"
                  :key="key"
                >
                  <v-list-item
                    class="tile ml-4 mr-2 my-2"
                    :href="dataUrl + item.md5"
                    target="_blank"
                  >
                    {{ item.filename }}
                  </v-list-item>

                  <v-btn
                    color="red"
                    class="ml-2 mr-4"
                    @click="removeAttach(item)"
                    v-if="addAction || editAction"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </span>
              </v-sheet>
            </v-col>
          </v-row>

          <!-- Shipping Warehouse -->
          <v-row
            v-if="myObj.status == 2 || auditAction == true || myObj.status == 3"
          >
            <v-col cols="12" class="pa-0 py-4">
              <v-icon left class="pb-1">mdi-apple-finder</v-icon>
              <span class="display-2" v-if="myObj.status != 3">
                {{ $vuetify.lang.t("$vuetify.shippingWarehouse") }}
              </span>
              <span class="display-2" v-if="myObj.status == 3">
                {{ $vuetify.lang.t("$vuetify.returnWarehouse") }}
              </span>
            </v-col>
          </v-row>

          <v-row
            v-if="myObj.status == 2 || auditAction == true || myObj.status == 3"
          >
            <v-col class="pb-4" cols="12">
              <v-select
                :items="warehouseOptions"
                v-model="myObj.warehouse_id"
                :label="$vuetify.lang.t('$vuetify.shippingWarehouse')"
                :readonly="auditAction == false"
                v-if="myObj.status != 3"
                :rules="generalRule"
              />

              <v-select
                :items="warehouseOptions"
                v-model="receiveForm.warehouse_id"
                :label="$vuetify.lang.t('$vuetify.returnWarehouse')"
                v-if="myObj.status == 3"
                :rules="generalRule"
                :readonly="myObj.returnID != undefined"
              />
            </v-col>
          </v-row>

          <!-- Product List -->
          <v-row class="pb-2">
            <v-col
              cols="12"
              lg="4"
              md="4"
              sm="4"
              class="py-2 px-0"
              align-self="center"
            >
              <span class="display-2">
                <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                {{ $vuetify.lang.t("$vuetify.goodsList") }}
              </span>
            </v-col>

            <v-spacer />

            <v-col
              class="d-flex justify-end align-center"
              cols="12"
              lg="3"
              md="3"
              sm="3"
              v-if="
                myObj.status != 2 && myObj.status != 3 && auditAction == false
              "
            >
              <v-btn color="primary" outlined dark @click="handleDialogProduct">
                {{ $vuetify.lang.t("$vuetify.goodsAdd") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <StockOutProductTable
                :head="selectedHeaders"
                :data="myObj.status == 3 ? saleTableData : selectedProduct"
                :myObj="myObj"
                :addAction.sync="addAction"
                :editAction.sync="editAction"
                :auditAction.sync="auditAction"
                :abolishAction.sync="abolishAction"
                :returnStatus="returnStatus"
                v-on:deleteitem="handleDeleteDialog"
              />

              <DialogDelete
                :dialogDelete.sync="dialogDelete"
                :deleteObj="deleteObj"
                @handleDelete="handleDeleteConfirm"
              />
            </v-col>
          </v-row>

          <AbolishStockOutDialog
            :dialogDelete.sync="dialogCancelReturn"
            :deleteObj="cancelData"
            @handleDelete="handleCancelReturnConfirm"
          />
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="py-9">
      <v-spacer />

      <v-btn
        color="red darken-2"
        outlined
        @click="cancelForm"
        v-if="myObj.returnID == undefined || myObj.returnID == ''"
      >
        {{ $vuetify.lang.t("$vuetify.cancel") }}
      </v-btn>

      <v-btn
        color="green darken-2"
        @click="validate"
        v-if="this.addAction == true && permissionCheck('add', 'picking')"
      >
        {{ $vuetify.lang.t("$vuetify.createStock") }}
      </v-btn>

      <v-btn
        color="green darken-2"
        @click="validate"
        v-if="
          this.auditAction == true && permissionCheck('modify', 'pickingAudit')
        "
      >
        {{ $vuetify.lang.t("$vuetify.audit") }}
      </v-btn>

      <v-btn
        color="green darken-2"
        @click="validate"
        v-if="this.editAction == true && permissionCheck('modify', 'picking')"
      >
        {{ $vuetify.lang.t("$vuetify.Confirm") }}
      </v-btn>

      <v-btn
        color="green darken-2"
        @click="validate"
        v-if="
          this.myObj.status == 2 && permissionCheck('modify', 'pickDelivery')
        "
      >
        {{ $vuetify.lang.t("$vuetify.deliveryCompleted") }}
      </v-btn>

      <v-btn
        color="green darken-2"
        @click="receivePicking"
        v-if="this.myObj.status == 3 && receiveProxyData.status != 2"
      >
        {{ $vuetify.lang.t("$vuetify.confirm3") }}
      </v-btn>

      <v-btn
        color="red"
        @click="handleCancelReturnDialog"
        v-if="this.myObj.status == 3 && receiveProxyData.status == 1"
      >
        {{ $vuetify.lang.t("$vuetify.cancelReturnRequest") }}
      </v-btn>

      <v-btn
        color="green darken-2"
        @click="returnCompalte"
        v-if="this.myObj.status == 3 && receiveProxyData.status == 2"
      >
        {{ $vuetify.lang.t("$vuetify.achieve") }}
      </v-btn>

      <v-btn
        color="red darken-2"
        outlined
        @click="cancelForm"
        v-if="myObj.status == 3 && myObj.returnID != undefined"
      >
        {{ $vuetify.lang.t("$vuetify.close") }}
      </v-btn>

      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
import {
  pickingList,
  pickingUpsert,
  pickingInfo,
  pickingCheck,
  pickingCancel,
  upsertReceive,
  checkReceive,
  cancelReceive,
  infoReceive,
  putaway,
} from "@/api/purchase";
import { warehouseList } from "@/api/purchase";
import serverConfig from "@/utils/serverConfig";
import { profilePicUpload } from "@/api/company";

export default {
  components: {
    DialogDelete: () => import("@/components/deleteDialog"),
    StockOutProductTable: () =>
      import("@/components/purchase/stockOutProductTable"),
    AbolishStockOutDialog: () =>
      import("@/components/purchase/abolishStockOutDialog"),
  },
  props: {
    dialog: Boolean,
    selectedProduct: Array,
    myObj: Object,
    addAction: Boolean,
    editAction: Boolean,
    auditAction: Boolean,
    abolishAction: Boolean,
  },
  data() {
    return {
      arr: [],
      fileArr: [],
      recevJson: {},
      returnObj: {},
      wait: false,
      blankIMG: "",
      dataUrl: serverConfig.img_url,
      types: [],
      note: "",
      valid: false,
      generalRule: [(v) => !!v || "this field is required"],
      dialogDelete: false,
      dialogCancelReturn: false,
      warehouseOptions: [],
      deleteIndex: -1,
      returnStatus: 0,
      receiveStatus: 0,
      receiveProxyData: {},
      receiveFileList: [],
      saleTableData: [],
      files: undefined,
      receiveForm: {
        id: "",
        // product_po: '',
        receive_order: "",
        products: null,
        warehouse_id: "",
        note: "",
        attach: [],
        type: 1,
      },
      receiveCheckData: {
        id: "",
        products: "",
      },
      createReturn: {},
      cancelData: {
        id: "",
        cancel_reason: "",
      },
      pickingInfoObj: {
        id: "",
        po: "",
        type: "",
        sale_po: "",
        product_po: "",
        relation_id: "",
        user_id: "",
        user_info: {
          mobile: "",
          name: "",
        },
        warehouse_id: "",
        warehouse_info: {
          address: "",
          contact: "",
          mobile: "",
          name: "",
        },
        status: 0,
        products: [
          {
            id: "",
            type: 1,
            nick_name: "",
            number: 0,
            real_number: 0,
            ReturnNumber: 0,
            image: "",
            name: "",
            en_name: "",
            unit: "",
            other_code: "",
          },
        ],
        check_user: {
          mobile: "",
          name: "",
          opt_time: "",
          status: 0,
          user_id: "",
        },
        ex_user: {
          mobile: "",
          name: "",
          opt_time: "--",
          status: 0,
          user_id: "",
        },
        cancel_user: {
          mobile: "",
          name: "",
          opt_time: "--",
          status: 0,
          user_id: "",
        },
        cancel_reason: "",
        note: "",
        gen_time: "",
        return_order: null,
        attach: [],
      },
      deleteObj: {
        id: "",
        type: 1,
        nick_name: "",
        number: 0,
        real_number: 0,
        ReturnNumber: 0,
        image: "",
        name: "",
        en_name: "",
        unit: "",
        other_code: "",
      },
      selectedHeaders: [],
      headers1: [
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "image",
          sortable: false,
          width: "100px",
        },
        {
          value: "productDescription",
          width: "400px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.number"),
          value: "number",
          width: "200px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "deleteProduct",
          sortable: false,
          align: "center",
        },
      ],
      headers2: [
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "image",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "productDescription",
          width: "400px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.number"),
          value: "number",
          width: "120px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.ActualQTY"),
          value: "real_number",
          width: "120px",
          sortable: false,
        },
      ],
      headers3: [
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "image",
          width: "100px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "productDescription",
          width: "400px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.ActualQTY"),
          value: "real_number",
          width: "120px",
          sortable: false,
        },
      ],
      headers4: [
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "image",
          width: "100px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "productDescription",
          width: "350px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.category"),
          value: "category",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.ActualQTY"),
          value: "real_number",
          width: "120px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.qtyReturn"),
          value: "receive_number",
          width: "120px",
          sortable: false,
        },
      ],
      headers5: [
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "image",
          width: "100px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "productDescription",
          width: "350px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.category"),
          value: "category",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.requestedReturnQty"),
          value: "number",
          width: "120px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.qtyReturn"),
          value: "receive_number",
          width: "120px",
          sortable: false,
        },
      ],
      headers6: [
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "image",
          width: "100px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "productDescription",
          width: "450px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.category"),
          value: "category",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.ActualQTY"),
          value: "real_number",
          width: "120px",
          sortable: false,
        },
      ],
      headers7: [
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "image",
          width: "100px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Products"),
          value: "productDescription",
          width: "450px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.category"),
          value: "category",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.requestedReturnQty"),
          value: "number",
          width: "120px",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    validate() {
      if (this.selectedProduct.length > 0) {
        this.myObj.products = this.selectedProduct;
        for (var i = 0; i < this.selectedProduct.length; i++) {
          this.selectedProduct[i].number = parseFloat(
            this.selectedProduct[i].number
          );
        }
        console.log("file", this.files);
        this.$refs.form.validate() == true
          ? this.$emit("handleData", this.myObj, this.files)
          : console.log("false");
        this.files = undefined;
      } else {
        this.$toast.info(
          this.$vuetify.lang.t("$vuetify.pleaseAddProductFirst")
        );
        this.valid = false;
      }
    },
    cancelForm() {
      this.resetValidation();
      this.$emit("update:dialog", false);
      this.$emit("update:addAction", false);
      this.$emit("update:editAction", false);
      this.$emit("update:auditAction", false);
      this.$emit("update:abolishAction", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    removeAttach(item) {
      this.myObj.attach.splice(this.myObj.attach.indexOf(item), 1);
    },
    handleDialogProduct() {
      this.$emit("selectProduct");
      this.dialogProduct = true;
    },
    handleDeleteDialog(obj) {
      this.deleteObj = obj;
      this.deleteIndex = this.selectedProduct.indexOf(obj);
      this.dialogDelete = true;
    },
    handleDeleteConfirm() {
      if (this.deleteIndex > -1) {
        this.selectedProduct.splice(this.deleteIndex, 1);
        this.deleteIndex = -1;
      }
      this.dialogDelete = false;
    },
    handleCancelReturnDialog() {
      this.dialogCancelReturn = true;
    },
    handleCancelReturnConfirm(obj) {
      this.dialogCancelReturn = false;
      this.cancelData.cancel_reason = obj.cancel_reason;
      this.cancelReceiveDialog();
    },
    getInfo() {
      if (this.myObj.id != "" && this.myObj.id != undefined) {
        pickingInfo(this.myObj.id).then((res) => {
          this.pickingInfoObj = res.item;

          this.pickingInfoObj.userInfo_name = this.pickingInfoObj.user_info.name;
          this.pickingInfoObj.approver = this.pickingInfoObj.check_user.name;
          this.pickingInfoObj.approveDate = this.pickingInfoObj.check_user.opt_time;
          this.pickingInfoObj.determinePerson = this.pickingInfoObj.cancel_user.name;
          this.pickingInfoObj.determineDate = this.pickingInfoObj.cancel_user.opt_time;

          this.receiveForm.receive_order = this.pickingInfoObj.id;
          this.receiveForm.warehouse_id = this.pickingInfoObj.warehouse_id;
        });
      }
    },
    getWarehouse() {
      warehouseList().then((data) => {
        var warehouses = [...data.items];

        for (var i = 0; i < warehouses.length; i++) {
          this.warehouseOptions.push({
            text: warehouses[i].name,
            value: warehouses[i].id,
          });
        }
      });
    },

    ////// Sale Return Functions //////
    saleReturn(data) {
      this.resetReceiveForm();
      this.receiveStatus = 0;
      this.receiveFileList = [];
      this.saleTableData = [];
      this.receiveProxyData = {};
      pickingInfo(data.id).then((res) => {
        if (res.item) {
          this.createReturn = res.item;
          this.saleTableData = res.item.products;
          this.receiveForm.receive_order = res.item.id;
          // this.receiveForm.product_po = res.item.po
          this.receiveForm.type = res.item.type;
          this.receiveForm.warehouse_id = res.item.warehouse_id;
          this.receiveDialog = true;
        }
      });
    },
    receivePicking() {
      // const arr = [];
      // const fileArr = [];
      // const recevJson = {};
      const returnObj = {};
      this.createReturn.return_order &&
        this.createReturn.return_order.forEach((item) => {
          if (item.status !== -1) {
            item.products &&
              item.products.forEach((i) => {
                if (returnObj[i.id] === undefined) {
                  returnObj[i.id] = 0;
                }
                if (item.status === 1) {
                  returnObj[i.id] += i.number;
                } else if (item.status === 2) {
                  returnObj[i.id] += i.real_number;
                }
              });
          }
        });
      let found = false;
      let apple = false;
      this.saleTableData.forEach((item) => {
        var json = {};
        this.recevJson[item.id] = Number(item.receive_number);
        json["id"] = item.id;
        json["number"] = Number(item.receive_number);
        json["type"] = item.type;
        this.arr.push(json);
        for (const i in returnObj) {
          if (
            i === item.id &&
            item.receive_number + returnObj[i] > item.real_number
          ) {
            found = true;
          }
        }
        if (item.receive_number > item.number) {
          apple = true;
        }
      });

      this.receiveFileList.length > 0
        ? this.receiveFileList.forEach((p) => this.fileArr.push(p.md5))
        : "";
      if (this.files != undefined) {
        const fileForm = new FormData();
        fileForm.append("file", this.files);

        profilePicUpload(fileForm).then((res) => {
          this.fileArr.push(res.data.md5);
          this.receiveForm.attach = JSON.stringify(this.fileArr);
          console.log("attachobj", this.receiveForm);
          this.wait = true;
        });
      } else {
        this.receiveForm.attach = JSON.stringify(this.fileArr);
        this.wait = true;
      }
    },
    resetReceiveForm() {
      this.receiveForm.id = "";
      this.receiveForm.product_po = "";
      this.receiveForm.receive_order = "";
      this.receiveForm.products = "";
      this.receiveForm.note = "";
      this.receiveForm.attach = "";
      this.receiveForm.type = "";
      this.receiveFileList = [];
      this.saleTableData = [];
    },
    returnCheck(data) {
      infoReceive(data.returnID).then((res) => {
        // res.item.attach &&
        //   res.item.attach.forEach((item) => {
        //     item.name = item.filename;
        //   });
        res.item.products &&
          res.item.products.forEach((item) => {
            item.receive_number = item.number;
          });
        this.createReturn = {};
        this.receiveFileList = res.item.attach;
        this.receiveProxyData = res.item;
        this.receiveStatus = this.receiveProxyData.status;
        this.receiveDialog = true;
        this.receiveForm.note = res.item.note;
        this.saleTableData = res.item.products;
        this.receiveCheckData.id = res.item.id;
        this.cancelData.id = res.item.id;

        this.returnStatus = data.returnStatus;
      });
    },
    cancelReceiveDialog() {
      cancelReceive(this.cancelData).then((res) => {
        this.$emit("update:dialog", false);
        this.$emit("getData");
      });
    },
    returnCompalte() {
      putaway({ id: this.receiveProxyData.id }).then((res) => {
        this.$emit("update:dialog", false);
        this.$emit("getData");
      });
    },
  },
  watch: {
    dialog() {
      this.receiveStatus = 0;
      this.getInfo();
      if (this.myObj.status == 1) {
        if (this.editAction == true) {
          this.selectedHeaders = this.headers1;
        } else if (this.auditAction == true) {
          this.selectedHeaders = this.headers2;
        }
      } else if (this.myObj.status == 2) {
        this.selectedHeaders = this.headers3;
      } else if (this.myObj.status == 3) {
        this.myObj.attach = [];
        if (this.myObj.returnID != undefined) {
          this.returnCheck(this.myObj);

          if (this.myObj.returnStatus == 1) {
            this.selectedHeaders = this.headers5;
          } else if (
            this.myObj.returnStatus == 2 ||
            this.myObj.returnStatus == 3
          ) {
            this.selectedHeaders = this.headers6;
          } else if (this.myObj.returnStatus == -1) {
            this.selectedHeaders = this.headers7;
          }
        } else {
          this.saleReturn(this.myObj);
          this.selectedHeaders = this.headers4;
        }
      } else {
        this.selectedHeaders = this.headers1;
      }
    },
    wait() {
      if (this.receiveStatus === 0) {
        // if (found) {
        //   this.$message.error(this.$t('all.tianxieQtyReturn'))
        //   return false
        // }
        this.receiveForm.products = JSON.stringify(this.arr);
        this.receiveForm.attach = JSON.stringify(this.fileArr);
        upsertReceive(this.receiveForm).then((res) => {
          this.receiveDialog = false;
          this.resetReceiveForm();
          this.$emit("getData");
          this.$emit("update:dialog", false);
        });
      } else if (this.receiveStatus === 1) {
        // if (apple) {
        //   this.$message.error(this.$t('all.tianxieQtyReturn'))
        //   return false
        // }
        this.receiveCheckData.products = JSON.stringify(this.recevJson);
        checkReceive(this.receiveCheckData).then((res) => {
          this.receiveDialog = false;
          this.resetReceiveForm();
          this.$emit("getData");
          this.$emit("update:dialog", false);
        });
      } else {
        this.resetReceiveForm();
        this.$emit("update:dialog", false);
        this.$emit("getData");
      }
      this.files = undefined;
    },
  },
  mounted() {
    this.getWarehouse();
  },
};
</script>
